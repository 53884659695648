<template>
  <div style="background: #f5f6f7">
    <div class="content-box">
      <div v-if="navList" class="breadcrumb">
        <Breadcrumb :data="navList" :is$t="true" />
      </div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'

import './index.less'
export default {
  name: 'ContentBox',
  components: {
    Breadcrumb,
  },
  props: {
    navList: Array,
  },
  mounted() {},
  methods: {},
}
</script>
