<template>
  <el-card style="border: 0" shadow="never" :body-style="{ border: '0' }">
    <el-form
      label-suffix="："
      :inline="true"
      class="demo-form-inline"
      label-width="100px"
      :label-position="labelPosition"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item :label="$t('75cc586')">
            <el-select
              v-model="values.type"
              :placeholder="$t('22d3ac0')"
              style="width: 260px"
            >
              <el-option
                v-for="option in typeOption"
                :key="option.value"
                :label="option.name"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8" style="text-align: left">
          <el-form-item :label="$t('c6598d7')">
            <el-date-picker
              v-model="values.date"
              type="daterange"
              range-separator="~"
              :start-placeholder="$t('c01cae1')"
              :end-placeholder="$t('0e0d505')"
              style="width: 260px"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="$t('6e491c1')">
            <el-input
              v-model="values.content"
              :placeholder="$t('4b83f94')"
              style="width: 260px"
            />
          </el-form-item>
        </el-col>

        <el-col :span="24" style="text-align: right">
          <el-form-item>
            <el-button type="primary" @click="onSubmit">{{ $t('79dc812') }}</el-button>
            <el-button @click="onReset">{{ $t('1c4056e') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import api from '@/api/common'
import { CONSULTING_TYPE, toList } from '@/utils/constants'

export default {
  name: 'ContentFilter',
  data() {
    return {
      typeOption: toList(CONSULTING_TYPE),
      values: {
        type: undefined,
        date: undefined,
        content: undefined,
      },
      pickerOptions: {
        disabledDate(date) {
          const today = new Date()
          today.setHours(23)
          return date > today
        },
      },
      labelPosition: localStorage.getItem("lang") === "zh" ? "left" : "top"
    }
  },
  mounted() {
    api.getDictionaryList({ type: 'BUSINESS_TYPE' }).then((res) => {
      if (res && res.content) {
        // console.log(res.content);
        this.businessOptions = [...res.content]
      }
    })
  },
  beforeUpdate() {
    this.labelPosition = localStorage.getItem("lang") === "zh" ? "left" : "top"
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.values)
    },
    onReset() {
      this.values = {}
      this.$emit('reset', {})
    },
    disabledDate(date) {
      // debugger
    },
  },
}
</script>

<style>
</style>
