<template>
  <ContentBox>
    <Card style="margin-top: 20px">
      <template v-slot:header>{{ $t('d3d7417') }}</template>
      <template v-slot:actions>
        <el-button
          style="
            height: 32px;
            padding: 8px 22px;
            border-color: rgba(0, 164, 255, 1);
          "
          @click="jumpAdd"
        >
          <Icon :src="sendImage" size="12" />
          <span style="color: rgba(0, 164, 255, 1); font-size: 14px">
            {{ $t('c246d22') }}
          </span>
        </el-button>
      </template>
    </Card>
    <ContentFilter @submit="handleSubmit" @reset="handleReset" />
    <Card
      :body-style="{ border: 0, marginTop: '20px' }"
      shadow="never"
    >
      <template v-slot:header>{{ $t('f40c091') }}</template>
      <el-table
        :data="data || []"
        style="width: 100%"
        :cell-class-name="cellClassName"
        @cell-click="handleRowClick"
      >
        <el-table-column
          prop="date"
          :label="$t('285e24a')"
          width="80"
          :formatter="(_, __, ___, i) => (pageInfo.currentPage - 1) * pageInfo.size + i + 1"
        />
        <el-table-column prop="title" :label="$t('0ea635c')" />
        <el-table-column
          prop="type"
          :label="$t('75cc586')"
          :formatter="(_, __, value) => CONSULTING_TYPE[value]"
          width="180"
        />
        <el-table-column prop="replyTime" :label="$t('9397544')" width="180" />
      </el-table>
      <Pagination :page-info="pageInfo" @changePage="changePage" />
    </Card>
  </ContentBox>
</template>

<script>
// import "./index.less";
import ContentBox from "@/components/ContentBox"

import Pagination from "@/components/Pagination"
import Card from "@/components/Card"
import Icon from "@/components/Icon"

import { CONSULTING_TYPE } from "@/utils/constants"

import sendImage from "@/assets/content/send.png"
import viewImage from "@/assets/content/view.png"

import ContentFilter from "./filter"

import api from "@/api/consult"

import { mapGetters, mapActions } from "vuex"

export default {
  name: "RequestManage",
  components: {
    ContentFilter,
    Pagination,
    Card,
    Icon,
    ContentBox,
  },
  props: {},
  data() {
    return {
      CONSULTING_TYPE,
      viewImage,
      sendImage,
      data: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total: 0,
      },
      searchData: {},
    }
  },
  computed: {
    ...mapGetters(["dictionaries"]),
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions(["getAreaTree", "getDictionary"]),
    cellClassName({ column }) {
      if (column.property === "title") {
        return "table-title"
      }
      return ""
    },
    jumpAdd() {
      this.$router.push("/business/release")
    },
    handleSubmit(values) {
      this.pageInfo.currentPage = 1
      const newValues = { ...values }
      delete newValues.date
      if (values.date) {
        newValues.startTime = values.date[0]
          .toLocaleDateString()
          .replace("/", "-")
          .replace("/", "-")
        newValues.endTime = values.date[1]
          .toLocaleDateString()
          .replace("/", "-")
          .replace("/", "-")
      }
      this.searchData = newValues
      this.getList()
    },
    handleReset() {
      this.searchData = {}
      this.getList()
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getList()
    },
    handleRowClick(row, column, cell) {
      // console.log(row, column, cell);
      if (column.property === "title") {
        this.$router.push({
          path: `/business/detail?id=${row.id}`,
        })
      }
    },
    getList(params = {}) {
      const searchData = this.searchData
      api
        .get({
          size: this.pageInfo.size,
          page: this.pageInfo.currentPage,
          ...searchData,
          ...params,
        })
        .then((res) => {
          if (res) {
            this.data = res.content
            this.pageInfo.total = res.page.total
          }
        })
    },
  },
}
</script>
<style >
.table-title {
  cursor: pointer;
}
.table-title:hover {
color: rgba(0, 164, 255, 1);
}
</style>
